























































































































































































































































































































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPopover,
  BTooltip,
  BFormCheckbox,
  BPagination,
  BRow,
  BTable,
  BBadge,
  BAlert,
  BFormGroup,
  VBTooltip,
  BAvatar,
  BAvatarGroup,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  FicheIncident,
  FicheIncidentStatistiques,
  FicheIncidentStatut,
} from "../../api/models/fichesIncident/ficheIncident";
import { FicheIncidentGraviteEnum } from "@/api/models/enums/ficheIncidentGraviteEnum";
import { FichesIncidentSearchParams } from "../../api/models/fichesIncident/ficheIncident";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { Processus } from "@/api/models/fichesIncident/processus";
import { BSpinner } from "bootstrap-vue";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import FichesIncidentStatistiques from "./FichesIncidentStatistiques.vue";

Vue.directive("b-tooltip", VBTooltip);
@Component({
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormCheckbox,
    BAvatar,
    BAvatarGroup,
    BPopover,
    BSpinner,
    BTooltip,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    SearchableVueSelect,
    FichesIncidentStatistiques,
    FeatherIcon,
  },
})
export default class ListFichesIncidents extends Vue {
  mainLoading = true;
  // Filtres
  perPageOptions = [2, 5, 10, 25, 50, 100];

  params: FichesIncidentSearchParams = {
    pageNumber: 1, // Le numéro de la page actuelle
    pageSize: 10, // La taille de la page
    search: "", // La requête de recherche
    orderBy: "dateIncident", // Le champ par lequel trier
    orderByDescending: false, // Direction du tri
    displayResolved: true, // Si les éléments résolus doivent être affichés
    displayOnlyWithPlanAction: false, // Si les éléments avec plan d'action uniquement doivent être affichés
    onlyMine: this.hasUpdateRights ? false : true, // Si les éléments me concernant seulement doivent être affichés
    filtreStatuts: [], // Liste des statuts pour filtrer
    filtreProcessus: [], // Liste des processus pour filtrer
    filtreGravites: [], // Liste des gravités pour filtrer
    filtreTypesIncident: [], // Liste des types d'incidents pour filtrer
  };

  modalPerfShow = false;

  graviteEnum = FicheIncidentGraviteEnum;
  tableColumns = [
    { key: "numero", label: "Numéro", sortable: true },
    { key: "titre", sortable: true },
    { key: "responsable", sortable: true },
    { key: "assignes", label: "Assigné(e)/s", sortable: false },
    { key: "typeIncident", label: "Type d'incident", sortable: true },
    { key: "processus", label: "Type de processus", sortable: true },
    { key: "statut", sortable: false },
    { key: "gravite", sortable: true, label: "Gravité" },
    { key: "dateIncident", sortable: true, label: "Date de l'incident" },
    { key: "dateCreation", sortable: true, label: "Date de création" },
  ];

  paginatedListFichesIncident = new PaginatedList<FicheIncident>();
  typesIncidentOptions = [];
  gravitesOptions: {
    key: FicheIncidentGraviteEnum;
    text: string;
  }[] = [
    { key: FicheIncidentGraviteEnum.FAIBLE, text: "Faible" },
    { key: FicheIncidentGraviteEnum.MOYENNE, text: "Moyenne" },
    { key: FicheIncidentGraviteEnum.HAUTE, text: "Haute" },
  ];
  processusOptions: Processus[] = [];
  statutOptions: FicheIncidentStatut[] = [];
  localisationOptions = [];
  debounceTimeout: any = null;
  fichesIncidentStatistique: FicheIncidentStatistiques = {
    fichesIncidentNombre: 0,
    fichesIncidentNonResoluesNombre: 0,
    fichesIncidentResoluesNombre: 0,
    fichesIncidentNonResoluesPourcentage: 0,
    fichesIncidentResoluesPourcentage: 0,
    intervalleResolutionMoyen: 0,
  };

  avatarColors: string[] = [
    "#3498db", // Bleu clair
    "#2ecc71", // Vert menthe
    "#e74c3c", // Rouge doux
    "#f39c12", // Orange
    "#9b59b6", // Violet doux
    "#34495e", // Bleu nuit
    "#1abc9c", // Vert d'eau
    "#e67e22", // Orange foncé
    "#95a5a6", // Gris clair
    "#d35400", // Orange brûlé
  ];
  avatarColorsMap: { [key: string]: string } = {};

  async created() {
    await this.loadListFichesIncident({});
    await this.loadTypesIncidentOptions();
    await this.loadListProcesssus();
    await this.loadStatutOptions();
    await this.loadLocalisationsOptions();
    await this.loadFichesIncidentStatistiques();

    this.mainLoading = false;
  }

  getInitials(nom: string, prenom: string) {
    const initialNom = nom ? nom.charAt(0).toUpperCase() : "";
    const initialPrenom = prenom ? prenom.charAt(0).toUpperCase() : "";
    return `${initialNom}${initialPrenom}`;
  }
  getRandomAvatarColor(key: string) {
    // Si la couleur existe déjà pour cet avatar, on la réutilise
    if (!this.avatarColorsMap[key]) {
      const randomIndex = Math.floor(Math.random() * this.avatarColors.length);
      this.avatarColorsMap[key] = this.avatarColors[randomIndex];
    }
    return this.avatarColorsMap[key];
  }

  @Watch("params", { deep: true, immediate: false })
  async paramsChanged() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout); // Annuler le timeout précédent s'il existe
    }

    this.debounceTimeout = setTimeout(async () => {
      this.mainLoading = true;
      try {
        await this.loadListFichesIncident({});
      } finally {
        this.mainLoading = false;
      }
    }, 500); // Délai de 500 ms avant d'exécuter la fonction
  }

  updateSort(params: any) {
    this.params.orderBy = params.sortBy;
    this.params.orderByDescending = params.sortDesc;
  }

  async loadListFichesIncident(params: any) {
    if (!params || params?.reset) {
      this.paginatedListFichesIncident = new PaginatedList<FicheIncident>();
    }
    await this.$http.myressif.fichesIncident
      .searchPaginatedList(this.params)
      .then(
        (response: PaginatedList<FicheIncident>) => {
          this.paginatedListFichesIncident = response;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Problème de chargement des fiches incidents",
            text: error.message,
          });
        }
      );
  }

  async loadListProcesssus() {
    await this.$http.myressif.processus.searchPaginatedList(1, 20).then(
      (response: PaginatedList<Processus>) => {
        this.processusOptions = response.items;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des processus",
          text: error.message,
        });
      }
    );
  }

  async loadTypesIncidentOptions() {
    await this.$http.myressif.parametres.getByNom("fi_type_incident").then(
      (res: any) => {
        if (res.valeur) {
          let options: [] = JSON.parse(res.valeur);
          this.typesIncidentOptions.push(...options);
        }
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des types d'incident",
          text: error.message,
        });
      }
    );
  }

  async loadLocalisationsOptions() {
    await this.$http.myressif.parametres.getByNom("fi_localisation").then(
      (res: any) => {
        if (res.valeur) {
          let options: [] = JSON.parse(res.valeur);
          this.localisationOptions.push(...options);
        }
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des localisations",
          text: error.message,
        });
      }
    );
  }

  async loadStatutOptions() {
    await this.$http.myressif.parametres.getByNom("fi_statut").then(
      (res: any) => {
        if (res.valeur) {
          let options = JSON.parse(res.valeur);
          this.statutOptions = options.map((option: FicheIncidentStatut) => {
            return {
              id: option.id,
              valeur: option.valeur,
              couleur: option.couleur,
              resolu: option.resolu,
              defaut: option.defaut,
            };
          });
        }
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des statuts",
          text: error.message,
        });
      }
    );
  }

  formatDate(date: string): Date {
    const dateSplitted = date.split("/");
    return new Date(
      parseInt(dateSplitted[2]), // Année
      parseInt(dateSplitted[1]) - 1, // Mois (0-indexé)
      parseInt(dateSplitted[0]) // Jour
    );
  }

  async loadFichesIncidentStatistiques() {
    await this.$http.myressif.fichesIncident.getStatistiques().then(
      (response: FicheIncidentStatistiques) => {
        this.fichesIncidentStatistique = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Problème de chargement des statistiques",
          text: error.message,
        });
      }
    );
  }

  async exportFichesIncident() {
    try {
      const response =
        await this.$http.myressif.fichesIncident.exportFichesIncident(
          this.params,
          { responseType: "blob" } // Ajoutez cette ligne pour s'assurer que la réponse est traitée comme un Blob.
        );

      // Assurez-vous que la réponse est bien un Blob
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      const date = new Date();
      const filename = `fiches_incident_export_${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}_${date.getTime()}.xlsx`;

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error while exporting fiches incidents:", error);

      const errorMessage = error?.response?.data?.errors
        ? error.response.data.errors[Object.keys(error.response.data.errors)[0]]
        : error?.response?.data?.detail ?? error.message;

      errorAlert.fire({
        text: errorMessage,
      });
    }
  }

  getFicheIncidentStatut(id: number): FicheIncidentStatut {
    var statut = this.statutOptions.find((option: any) => option.id === id);
    if (statut && statut.valeur) return statut;
    else return { id: 0, valeur: "Inconnu", couleur: "#343a40", resolu: false };
  }

  get paginationFrom() {
    return (
      this.params.pageSize! * (this.params.pageNumber! - 1) +
      (this.paginatedListFichesIncident.items.length ? 1 : 0)
    );
  }

  get paginationTo() {
    return (
      this.params.pageSize! * (this.params.pageNumber! - 1) +
      this.paginatedListFichesIncident.items.length
    );
  }

  async redirectToDetailsPage(item: any) {
    // Redirection vers la page de détails de la fiche incident
    this.$router.push({
      name: "fiches-incident-details",
      params: { id: item.id },
    });
  }

  get hasUpdateRights(): boolean {
    return this.$can("FICHES_INCIDENT:update");
  }
}
