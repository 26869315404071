






























































import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { BRow, BCol, BCardText, BBadge } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { FicheIncidentStatistiques } from "../../api/models/fichesIncident/ficheIncident";

@Component({
  components: {
    VueApexCharts,
    BCardText,
    BBadge,
    BRow,
    BCol,
    FeatherIcon,
  },
})
export default class FichesIncidentStatistiques extends Vue {
  @Prop({ type: Object, default: () => {} }) data!: FicheIncidentStatistiques;

  // support tracker with no data to be displayed
  supportTrackerRadialBarResolues : any = null;

  supportTrackerRadialBarNonResolues : any = null;

  async created() {
    setTimeout(() => {
      this.supportTrackerRadialBarResolues = {
        series: [this.data.fichesIncidentResoluesPourcentage],
        chartOptions: {
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "70%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24,
                },
              },
              track: {
                background: "#fff",
                strokeWidth: "67%",
                margin: 0,
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ["#ABE5A1"],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          labels: ["Résolues"],
        },
      };

      this.supportTrackerRadialBarNonResolues = {
        series: [this.data.fichesIncidentNonResoluesPourcentage],
        chartOptions: {
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "70%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24,
                },
              },
              track: {
                background: "#fff",
                strokeWidth: "67%",
                margin: 0,
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35,
                },
              },
              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ["#ABE5A1"],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          labels: ["Non Résolues"],
        },
      };
    }, 1000);
  }
}
